<template>
  <div class="card mb-5 mb-xl-10">
    <div class="card-header border-0 cursor-pointer">
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Memo</h3>
      </div>
    </div>

    <div class="card-body border-top p-9">
      <MemoWidget 
            memoType="Student"
            v-bind:memoTypeId="studentId"
            pageSize=20 />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MemoWidget from "@/views/widgets/Memo.vue"
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "student-memo",
  components: {
    MemoWidget
  },
  setup() {
    const route = useRoute();
    const studentId = route.params.id; 

    onMounted(() => {
      setCurrentPageBreadcrumbs("Memo", ["Student"]);
    });

    return {
      studentId
    };
  },
});
</script>

<style scoped>
* >>> .delete_icon {
    opacity: 0.3;
}

* >>> .delete_icon:hover {
    pointer-events: all;
    opacity: 0.7 !important;
}

* >>> .add_icon{
    opacity: 0.3;
    color:red;
}

* >>> .add_icon:hover {
    pointer-events: all;
    opacity: 0.7 !important;
}
</style>

