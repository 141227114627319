
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import MemoWidget from "@/views/widgets/Memo.vue"
import { useRoute } from 'vue-router'

export default defineComponent({
  name: "student-memo",
  components: {
    MemoWidget
  },
  setup() {
    const route = useRoute();
    const studentId = route.params.id; 

    onMounted(() => {
      setCurrentPageBreadcrumbs("Memo", ["Student"]);
    });

    return {
      studentId
    };
  },
});
